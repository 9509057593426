// @flow strict
import React from 'react';

const Calendly = () => {
    return (
        <div style={{ height: "800px" }}>
            <iframe
                src="https://calendly.com/oreogundipe/chat-about-fusion"
                width="100%"
                height="100%"
                frameborder="0"
            ></iframe>
        </div>
    );
}

export default Calendly;